import { render, staticRenderFns } from "./AppHeading3.vue?vue&type=template&id=d070ad98&scoped=true"
import script from "./AppHeading3.vue?vue&type=script&lang=js"
export * from "./AppHeading3.vue?vue&type=script&lang=js"
import style0 from "./AppHeading3.vue?vue&type=style&index=0&id=d070ad98&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d070ad98",
  null
  
)

export default component.exports