<template>
    <div class="heading-2">
        <slot></slot>
    </div>
</template>
<script>
export default{
    data(){
        return{
            //
        }
    }
}
</script>
<style scoped>
 .heading-2{
        color: #4B465C;
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
    }
</style>