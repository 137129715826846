import http from "@/utils/http";
export async function upload_document(data) {
    const req = (await http.post('/documents',data));
    return req.data;   
}
export async function get_document(id) {
    const req = (await http.get(`/documents/${id}`));
    return req.data;   
}
export async function create_folder(data){
    const req = (await http.post(`/folders`,data));
    return req.data; 
}
export async function get_folder_list(role){
    const req = (await http.get(`/folders/${role}`));
    return req.data; 
}
export async function get_folder_by_name(name){
    const req = (await http.get(`/folders/search/`, {params:{folderName:name}}));
    return req.data; 
}
export async function get_subfolder_list(id){
    const req = (await http.get(`/folders/subfolders/${id}`));
    return req.data; 
}

export async function get_project_amounts(){
    const req = (await http.get(`/folders/amount-details`));
    return req.data;
}

export async function search_user_by_email(email){
    const req = (await http.get(`/user/search-clients-email/${email}`));
    return req.data; 
}
export async function add_collaburator(data){
    const req = (await http.put(`/documents/addcollaborators/${data.id}`,data.collaborator));
    return req.data; 
}
export async function remove_collaburator(data){
    const req = (await http.delete(`/documents/collaborators/${data.id}/${data.collaborator}`));
    return req.data; 
}
export async function get_documents_list(type,filters){
    const req = (await http.get(`/documents/${type}/filter`, {params:filters}));
    return req.data; 
}
export async function send_document(id){
    const req = (await http.put(`/documents/send/${id}`));
    return req.data;   
}
export async function get_sub_documents(id,filters){
    const req = (await http.get(`/folders/subdocuments/${id}`, {params:filters}));
    return req.data; 
}
export async function set_signature_area(data){
    const req = (await http.put(`/documents/signature-placements`, data));
    return req.data; 
}
export async function update_document_version(id,data){
    const req = (await http.put(`/documents/${id}`, data));
    return req.data; 
}
export async function add_comment(data){
    const req = (await http.post(`/comments`, data));
    return req.data; 
}
export async function get_comments(id){
    const req = (await http.get(`/comments/${id}`));
    return req.data; 
}

export async function save_to_backup(id){
    const req = (await http.post(`/documents/backup`,{documentId:id}));
    return req.data; 
}

export async function reject_document(id,data){
    const req = (await http.post(`/documents/reject/${id}`, data));
    return req.data; 
}

export async function sign_document(id,data){
    const req = (await http.put(`/documents/sign/${id}`, data));
    return req.data; 
}

export async function search_document_by_name(name){
    const req = (await http.get(`/documents/search`, {params:{documentName:name}}));
    return req.data; 
}

export async function document_version(data){
    const req = (await http.get(`/documents/file`, {params:data}));
    return req.data; 
}
export async function recent_documents(){
    const req = (await http.get(`/documents/recent`));
    return req.data; 
}
export async function get_documents_require_sign(filters, role){
    const req = (await http.get(`/documents/${role=='client'?'requiring-signatures':'sent-requiring-signatures'}`, {params:filters}));
    return req.data; 
}


export async function get_dashboard_stats(type){
    const req = (await http.get('/dashboard-reports/dashboard-stats', {params:type}));
    return req.data; 
}
export async function get_service_provider_stats(type){
    const req = (await http.get('/dashboard-reports/summary', {params:{timeframe:type}}));
    return req.data; 
}
export async function get_y2d_stats(){
    const req = (await http.get('/dashboard-reports/y2d-summary'));
    return req.data; 
}
export async function get_project_details(id){
    const req = (await http.get(`/folders/details/${id}`));
    return req.data; 
}
export async function add_collaborator_to_project({id,data}){
    const req = (await http.post(`/folders/add-collaborator/${id}`, data));
    return req.data; 
}
export async function remove_collaborator_from_project({projectId,id}){
    const req = (await http.delete(`/folders/collaborators/${projectId}/${id}`));
    return req.data; 
}
export async function update_project_details({id,data}){
    const req = (await http.patch(`/folders/${id}`,data));
    return req.data; 
}
export async function search_collaborator_by_project({id,email}){
    const req = (await http.post(`/folders/collaborators/search/${id}`, {email}));
    return req.data; 
}

export async function doc_viewed(id){
    const req = (await http.put(`/documents/review/${id}`));
    return req.data; 
}