<template>
  <div @scroll="scrollDown()">
    <div id="webviewer" ref="viewer" class="position-relative"  ></div>
    <div class="position-absolute d-flex align-items-center controls-card justify-content-center">
      <feather-icon icon="ChevronDownIcon" class="mx-8 cursor-pointer" size="24" @click="moveNext"></feather-icon>
      <div >{{ currentPage }} of {{totalPages}}</div>
      <feather-icon icon="ChevronUpIcon" class="mx-8 cursor-pointer" size="24" @click="movePrev"></feather-icon>
      <div class="border-left border-right  ">
        <feather-icon icon="ZoomInIcon" class="mx-8 cursor-pointer" size="24" @click="zoomIn" ></feather-icon>
        <feather-icon icon="ZoomOutIcon" class="mx-8 cursor-pointer" size="24" @click="zoomOut"></feather-icon>
      </div>
      
      <feather-icon class="mx-8 cursor-pointer" icon="MessageCircleIcon" size="24"></feather-icon>
    </div>
  </div>
  </template>
  
  <script>
  import WebViewer from "@pdftron/webviewer";
  
  export default {
    name: "WebViewer",
    props: { initialDoc: { type: String } },
    data() {
      return {
        viewer: null,
        currentInstance: null,
        totalPages:0,
        currentPage:1
      };
    },
    mounted() {
      const path = `${process.env.BASE_URL}webviewer`;
      const viewer = this.$refs.viewer;
  
      WebViewer({ 
        path, 
        initialDoc: this.initialDoc, 
        licenseKey: 'demo:1710146075898:7f3d91a60300000000562be7d21ae414db8d4ed0f1f4f50a54c85e33a3'  
      }, viewer).then((instance) => {
        const { documentViewer, annotationManager, Annotations } = instance.Core;
        instance.UI.disableElements([ 'leftPanel', 'leftPanelButton', 'header','contextMenuPopup']);
        instance.UI.setZoomLevel(1.5);
        this.currentInstance=instance;
        documentViewer.addEventListener("documentLoaded", () => {
          // const rectangleAnnot = new Annotations.RectangleAnnotation({
          //   PageNumber: 1,
          //   X: 100,
          //   Y: 150,
          //   Width: 200,
          //   Height: 50,
          //   Author: annotationManager.getCurrentUser(),
          // });
          // annotationManager.addAnnotation(rectangleAnnot);
          // annotationManager.redrawAnnotation(rectangleAnnot);
          
          this.totalPages = documentViewer.getPageCount();
          this.currentPage = documentViewer.getCurrentPage();


        });

        documentViewer.on('pageComplete', pageNumber => {
          // Update current page number on scroll
          this.currentPage = pageNumber
        })
        
      });
    },
    methods:{
      moveNext(){
        this.currentInstance.UI.goToNextPage()
        this.currentPage = this.currentInstance.Core.documentViewer.getCurrentPage()
      },
      movePrev(){
        this.currentInstance.UI.goToPrevPage()
        this.currentPage = this.currentInstance.Core.documentViewer.getCurrentPage()
      },
      zoomIn(){
        const currentZoomlevel=this.currentInstance.UI.getZoomLevel()
        this.currentInstance.UI.setZoomLevel(currentZoomlevel+0.25)
      },
      zoomOut(){
        const currentZoomlevel=this.currentInstance.UI.getZoomLevel()
        this.currentInstance.UI.setZoomLevel(currentZoomlevel-0.25)
      },
      scrollDown(){
        console.log("scrolling down")
      },
      addCollaborator(){
        const { annotationManager, Annotations } = this.currentInstance.Core;
          const rectangleAnnot = new Annotations.RectangleAnnotation({
            PageNumber: 1,
            X: 100,
            Y: 150,
            Width: 200,
            Height: 100,
            StrokeColor: new Annotations.Color(0, 75, 255, 1),
            Style:'dash',
            NoResize: true,
            Author: annotationManager.getCurrentUser(),
          });
          // const annot = new Annotations.FreeTextAnnotation(Annotations.FreeTextAnnotation.Intent.FreeText, {
          //   PageNumber: 1,
          //   X: 100,
          //   Y: 50,
          //   Width: 200,
          //   Height: 50,
          //   TextAlign: 'center',
          //   TextVerticalAlign: 'center',
          //   TextColor: new Annotations.Color(255, 0, 0, 1),
          //   StrokeColor: new Annotations.Color(0, 255, 0, 1),
          //   Style:'dash',
          // });

          annotationManager.addAnnotation(rectangleAnnot);
          annotationManager.redrawAnnotation(rectangleAnnot);
        // })
      }
    }
  };
  </script>
  
  <style scoped>
  #webviewer {
    height: 80vh;
  }
  .controls-card{
    background-color: #ffffff;
    width: 285px;
    padding: 10px 15px;
    border-radius: 30px;
    border:1px solid #D0D5DD;
    bottom:3%;
    left: 25%;
  }
  .mx-8{
    margin-left: 8px;
    margin-right: 8px;
  }
  .px-8{
    padding-left: 8px;
    padding-right: 8px;
  }
  </style>
  