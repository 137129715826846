<template>
  <div>
    <b-overlay :show="loading" :style="{minHieght:'100px'}">
      <template #overlay>
        <div class="text-primary d-flex align-items-center">
          <b-spinner label="Loading..." variant="primary"></b-spinner>
          <h4 class="ml-1 text-primary">{{loadedStatus}}% Loaded</h4>
        </div>
      </template>
    <div id="webviewer">
      <div class="d-flex justify-content-center align-items-center"  >
        <div class="pdf-div" :style="{width:`${zoom}%`}" >
          <vue-pdf-embed :style="{width:'100%'}"  ref="pdfDoc" :page="currentPage" :annotationLayer="false"  imageResourcesPath="@/assets/images/app-icons/doc.png" @rendered="handleDocumentRender" @loading-failed="renderFailed" @progress="fileUploadStatus" :source="initialDoc"/>
          <!-- <div class="bg-div"> -->

            <div :class="`dragable-div dragable-div-${s.box}`" class="shadow-none" :style="{top:`${canvasHeight*.20*(s.box-1)+5}px`, width:`${canvasWidth*.30}px`, height:`${boxHeight}px`}"  v-for="(s,index) in initialBoxes" :key="index" @click="()=>activeIndex=index">
              <div class="upper-div shadow-none" :style="{height:`${canvasHeight*.10}px`}"></div>
              <div class="dragable-inner  shadow-none d-flex align-items-center">
                  <div class="email-avatar shadow-none my-0">{{s.name.slice(0,1).toUpperCase()}}</div>
                  {{ s.name }}
              </div>
            </div>
            <div class="reciever-sign" :style="{width:`${canvasWidth*.30}px`, height:`${boxHeight}px`}">
              <span  :style="{paddingLeft:'6px',color:'#004bff'}">Place your signature here!</span>
            </div>
          
          <!-- </div> -->
          <!-- <signature-area class="dragable-div-6"/> -->
          <!-- <div class="dragable-div"  :style="{ borderColor:s.color}" v-for="(s,index) in signatureBoxes" :key="index"
           >
            <span  :style="{paddingLeft:'6px', color:s.color}">Place your signature hare!</span>
          </div> -->
        </div>

      </div>
    </div>
    <div class="position-absolute d-flex align-items-center controls-card justify-content-center" v-if="!loading">
      <feather-icon icon="ChevronUpIcon" :class="[(disablebtn||currentPage)<2?'disabled-btn':'']" class="mx-8 cursor-pointer" size="24" @click="()=>movePrev()"></feather-icon>
      <div >{{ currentPage }} of {{totalPages}}</div>
      <feather-icon icon="ChevronDownIcon" :class="[(disablebtn||currentPage>=totalPages)?'disabled-btn':'']" class="mx-8 cursor-pointer" size="24" @click="()=>moveNext()"></feather-icon>
      <div class="border-left  ">
        <feather-icon icon="ZoomInIcon" class="mx-8 cursor-pointer" :class="[zoom>=100?'disabled-btn':'']" size="24" @click="zoomIn()" ></feather-icon>
        <feather-icon icon="ZoomOutIcon" class="mx-8 cursor-pointer" :class="[zoom<=70?'disabled-btn':'']" size="24" @click="zoomOut"></feather-icon>
      </div>
      
      <!-- <feather-icon class="mx-8 cursor-pointer" icon="MessageCircleIcon" size="24"></feather-icon> -->
    </div>
    </b-overlay>
  </div>
</template>

<script>
import DocumentViewer from './DocumentViewer.vue'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import  Draggable  from 'draggable';
import { BOverlay,BSpinner, BAlert } from 'bootstrap-vue'
import {set_signature_area} from '@/apis/others/documents'
import SignatureArea from '@/components/functional-components/other/documents/SignatureArea'
import { $themeColors } from '@themeConfig'
export default {
  props: { 
    initialDoc: { type: String },
    defaultSignatureBoxes:[Array],
    moveSign:{
      type:[Boolean],
      default:true,
    }
  },
  components: {
    VuePdfEmbed,
    DocumentViewer,
    BOverlay,
    BSpinner,
    BAlert,
    SignatureArea
  },
  data() {
    return {
     show:false,
     currentPage: 1,
     totalPages: 1,
     loadedStatus:0,
     loading: true,
     scale: 1,
     zoom:85,
     xPosition:0,
     yPosition:0,
     pdfPosition:null,
     drag:false,
     signatureBoxes:[],
     initialBoxes:[
      {
        box:1,
        name:'Test Name'
      },
      {
        box:2,
        name:'Test Name'
      },
      {
        box:3,
        name:'Test Name'
      },
      {
        box:4,
        name:'Test Name'
      },
      {
        box:5,
        name:'Test Name'
      }
     ],
     activeIndex:0,
     receiverData:null,
     canvasWidth:0,
     canvasHeight:0,
     disablebtn:false,
     boxHeight:120,
     containerDomrect:null
    //  defaultSignatureBoxes:[]
    
    }
  },
  mounted() {
    this.boxHeight=window.innerWidth>425?120:70
    this.getInitialPositionofPdf()
    if(this.moveSign){
      this.initialBoxes.map((i,index)=>new Draggable(document.querySelector(`.dragable-div-${i.box}`), {onDragEnd:(e,x,y,ex, left, top)=>this.draged(x,y,index, top, left)}))
    }
    
    // this.defaultSignatureBoxes.map(s=>this.addDefaultBox(s))
    
     
  },
  methods:{
    handleDocumentRender(){
      this.totalPages = this.$refs.pdfDoc.pageCount
      this.loading = false
      this.$emit('currentPage',this.currentPage)
      this.disablebtn=false
      this.getCanvasHeight()
      console.log('doc render')
      setTimeout(()=>{
        const container = document.querySelector('.pdf-div');
        this.containerDomrect = container.getBoundingClientRect();
        console.log('doc render:',this.defaultSignatureBoxes)
        this.defaultSignatureBoxes.map(s=>this.addDefaultBox(s))
        if(this.receiverData!=null){
          this.setReceiverSignArea(this.receiverData)
        }
      },1000)
    },
    getCanvasHeight(){
      const canvas= document.querySelector('.vue-pdf-embed__page').firstElementChild;
      const canvasDomrect = canvas.getBoundingClientRect();
      this.canvasWidth=canvasDomrect.width;
      this.canvasHeight=canvasDomrect.height;
    },
    moveNext(){
      this.currentPage+=1;
      // this.setDragableDiv()
      // this.signatureBoxes=[],
      // this.defaultSignatureBoxes.map(s=>this.addDefaultBox(s))
      this.showHideSignatureBoxes()
      // setTimeOut(()=>this.showHideSignatureBoxes(),200)
      if(this.receiverData!=null){
        this.setReceiverSignArea(this.receiverData)
      }
      this.disablebtn = true
    },
    fileUploadStatus(e){
      this.loadedStatus = (e.loaded/e.total*100).toFixed(0)
    },
    setSignatureBox(){
      
    },
     movePrev(){
      this.currentPage-=1;
      // this.signatureBoxes=[],
      // this.defaultSignatureBoxes.map(s=>this.addDefaultBox(s))
      
      this.showHideSignatureBoxes()
      // this.setDragableDiv()
      // this.setSignatureBox()
      // setTimeOut(()=>this.showHideSignatureBoxes(),200);
      if(this.receiverData!=null){
        this.setReceiverSignArea(this.receiverData)
      }
      this.disablebtn = true
    },
    zoomIn(){
     this.zoom+=5
    //  const rectDom=this.getContainerDomrect()
    //  this.resetBoxesOnZoom(rectDom)

    //  if(this.receiverData!=null){
    //     this.setReceiverSignArea(this.receiverData,0, 25)
    //   }
    //  this.signatureBoxes=[],
    //   this.defaultSignatureBoxes.map(s=>this.addDefaultBox(s))
      // this.$refs.pdfViewer.style
      // this.scale+=0.25
    },
    zoomOut(){
     this.zoom-=5
    //  const rectDom=this.getContainerDomrect()
    //  this.resetBoxesOnZoom(rectDom)
    //  if(this.receiverData!=null){
    //     this.setReceiverSignArea(this.receiverData,0,-27)
    //   }
    //  this.signatureBoxes=[],
    // this.defaultSignatureBoxes.map(s=>this.addDefaultBox(s))
    },
    resetBoxesOnZoom({width,height}){
      console.log('canvas hieght and width:',width,height)
      this.signatureBoxes.map((i,index)=>{
        const currentX=i.x/i.canvasWidth*100
        const currentY=i.y/i.canvasHeight*100
        const newX=currentX*width/100
        const newY=currentY*height/100
        console.log('height and width:',newX, newY)
        i.x=newX
        i.y=newY
        document.querySelector(`.dragable-div-${i.boxNo}`).style.left=(i.x==0?xPosition+this.xPosition:i.x)+`px`
        document.querySelector(`.dragable-div-${i.boxNo}`).style.top=(i.y==0?yPosition+this.yPosition:i.y)+`px`
      })
    },
    getInitialPositionofPdf(){
      const container = document.querySelector('#webviewer');
      const element = document.querySelector('.pdf-div');
    
      // get the domrect for your targeted container
      const containerDomrect = container.getBoundingClientRect();
      // get the domrect for you relement you wanna have the x/y coordinates
      const elementDomrect = element.getBoundingClientRect();
      this.pdfPosition=elementDomrect
      // then simply subtract the x/y values of the container from the element
      const xCoord = elementDomrect.x - containerDomrect.x;
      const yCoord = elementDomrect.y - containerDomrect.y;
      this.xPosition=xCoord;
      this.yPosition=yCoord;

      // console.log('X coordinate: ' + xCoord);
      // console.log('Y coordinate: ' + yCoord);
    },
    // draged(e,index){
    //   this.signatureBoxes[index].xPosition =e.clientX-this.pdfPosition.x<0?0:e.clientX-this.pdfPosition.x;
    //   this.signatureBoxes[index].yPosition =e.clientY-this.pdfPosition.y<0?0:e.clientY-this.pdfPosition.y;
    // },
    addSignatureBox(details){
      this.signatureBoxes.push({
        collaboratorId:details.id,
        email:details.email,
        x:0,
        y:(120*this.signatureBoxes.length)+10,
        pageNo:details.pageNo?details.pageNo:this.currentPage,
        canvasWidth:this.canvasWidth,
        canvasHeight:this.canvasHeight,
        boxNo:this.signatureBoxes.length+1
      })
      const data= {
        documentId:this.$route.params.id,
        ...this.signatureBoxes[this.signatureBoxes.length-1]
      }
      set_signature_area(data).then(resp=>{
        console.log('updated:',resp)
      })
      this.initialBoxes[this.signatureBoxes.length-1].name=details.name
      document.querySelector(`.dragable-div-${this.signatureBoxes.length}`).style.opacity=1
      document.querySelector(`.dragable-div-${this.signatureBoxes.length}`).style.cursor='move'
    },

    getContainerDomrect(){
      const container = document.querySelector('.pdf-div');
      return container.getBoundingClientRect();
    },

    addDefaultBox(data){
        // this.containerDomrect = await this.getContainerDomrect();
        const xPosition = (data.x/ 100) * this.containerDomrect.width;
        const yPosition = (data.y/100) *  this.containerDomrect.height;
        this.signatureBoxes.push({
        collaboratorId:data.collaboratorId._id,
        email:data.collaboratorId.email,
        x:xPosition,
        y:yPosition,
        pageNo:data.pageNo?data.pageNo:this.currentPage,
        canvasWidth:this.canvasWidth,
        canvasHeight:this.canvasHeight,
        boxNo:this.signatureBoxes.length+1,
      })
        this.initialBoxes[this.signatureBoxes.length-1].name=`${data.collaboratorId.first_name} ${data.collaboratorId.last_name}`
      if(data.pageNo==this.currentPage){
        document.querySelector(`.dragable-div-${this.signatureBoxes.length}`).style.left=(xPosition==0?xPosition+this.xPosition:xPosition)+`px`
        document.querySelector(`.dragable-div-${this.signatureBoxes.length}`).style.top=(yPosition==0?yPosition+this.yPosition:yPosition)+`px`
        document.querySelector(`.dragable-div-${this.signatureBoxes.length}`).style.opacity=1
        if(this.moveSign){
          document.querySelector(`.dragable-div-${this.signatureBoxes.length}`).style.cursor='move'
        }

      }else{
        document.querySelector(`.dragable-div-${this.signatureBoxes.length}`).style.opacity=0
        document.querySelector(`.dragable-div-${this.signatureBoxes.length}`).style.cursor='default'
      }
      
      
      
     
    },
    draged(x,y,i,){
      const container = document.querySelector('.pdf-div');
      const containerDomrect = container.getBoundingClientRect();
      // this.signatureBoxes[i].x=x-this.xPosition<0?0:(x/ containerDomrect.width) * 100-this.xPosition;
      // this.signatureBoxes[i].y=y<0?0:(y / containerDomrect.height) * 100;
      this.signatureBoxes[i].x=x-this.xPosition<0?0:x;
      this.signatureBoxes[i].y=y<0?0:y; 
      this.signatureBoxes[i].canvasWidth=this.canvasWidth;
      this.signatureBoxes[i].canvasHeight=this.canvasHeight;
      const data= {
        documentId:this.$route.params.id,
        ...this.signatureBoxes[i],
        x:x-this.xPosition<0?0:x/containerDomrect.width*100,
        y:y<0?0:y/containerDomrect.height*100
      }
      set_signature_area(data).then(resp=>{
        console.log('updated:',resp)
      })
      // console.log('current position: ',this.signatureBoxes[i])
      // console.log('draged end: ',x, y,i)
    },
    setReceiverSignArea(data,xOffset=0, yOffset=0){
        this.receiverData=data;
        if(data.pageNo==this.currentPage){
          const container = document.querySelector('.pdf-div');
          const containerDomrect = container.getBoundingClientRect();
            const xPosition = (data.x/ 100) * containerDomrect.width;
            const yPosition = (data.y/100) *  containerDomrect.height+yOffset;
            document.querySelector(`.reciever-sign`).style.opacity=1
            document.querySelector(`.reciever-sign`).style.left=(xPosition==0?xPosition+this.xPosition:xPosition)+`px`
            document.querySelector(`.reciever-sign`).style.top=(yPosition==0?yPosition+this.yPosition:yPosition)+`px`
        }else{
          document.querySelector(`.reciever-sign`).style.opacity=0
        }
    },
    showHideSignatureBoxes(){
      this.signatureBoxes.map((i,index)=>{
        console.log('current sign:',i)
        document.querySelector(`.dragable-div-${i.boxNo}`).style.opacity=0
        document.querySelector(`.dragable-div-${i.boxNo}`).style.cursor='default'
        if(i.pageNo==this.currentPage){
          console.log('current page', this.currentPage)
          document.querySelector(`.dragable-div-${i.boxNo}`).style.left=(i.x==0?xPosition+this.xPosition:i.x)+`px`
          document.querySelector(`.dragable-div-${i.boxNo}`).style.top=(i.y==0?yPosition+this.yPosition:i.y)+`px`
          document.querySelector(`.dragable-div-${i.boxNo}`).style.opacity=1
          document.querySelector(`.dragable-div-${i.boxNo}`).style.cursor='move'
        }
        console.log('i box:',i.boxNo)
        
      })
    },
    removeSignatureBox(c){
      let index = this.signatureBoxes.findIndex(s=>s.email==c.email)
      if(this.signatureBoxes[index].pageNo==this.currentPage){
        document.querySelector(`.dragable-div-${index+1}`).style.opacity=0
        document.querySelector(`.dragable-div-${index+1}`).style.cursor='default'
        this.signatureBoxes.splice(index,1)
      }else{
        let prevPage=this.currentPage
        this.currentPage=this.signatureBoxes[index].pageNo
        if(this.signatureBoxes[index].pageNo==this.currentPage){
          
          document.querySelector(`.dragable-div-${index+1}`).style.opacity=0
          document.querySelector(`.dragable-div-${index+1}`).style.cursor='default'
          this.signatureBoxes.splice(index,1)
        }
        this.currentPage=prevPage
      }
    },
    createAndAppendDiv(boxData, index) {
        // Create the main div
        var newDiv = document.createElement('div');
        newDiv.className = `dragable-div dragable-div-${boxData.box}`;
        newDiv.style.top = `${120 * (boxData.box - 1) + 5}px`;
        newDiv.setAttribute('data-index', index);

        // Set the click event listener
        newDiv.addEventListener('click', function() {
            activeIndex = index;
            console.log('Active index set to:', activeIndex);
        });

        // Create the upper-div
        var upperDiv = document.createElement('div');
        upperDiv.className = 'upper-div';

        // Create the dragable-inner div
        var dragableInner = document.createElement('div');
        dragableInner.className = 'dragable-inner d-flex align-items-center';

        // Create the email-avatar div
        var emailAvatar = document.createElement('div');
        emailAvatar.className = 'email-avatar';
        emailAvatar.textContent = boxData.name.slice(0, 1).toUpperCase();

        // Create the text node for the name
        var nameText = document.createTextNode(` ${boxData.name}`);

        // Append the email-avatar and name text to dragable-inner
        dragableInner.appendChild(emailAvatar);
        dragableInner.appendChild(nameText);

        // Append the upper-div and dragable-inner to the main div
        newDiv.appendChild(upperDiv);
        newDiv.appendChild(dragableInner);

        // Append the new div to the target div
        document.querySelector('.vue-pdf-embed__page').appendChild(newDiv);
    },
    renderFailed(){
      this.$emit('renderFailed');
      this.loading=false;
      setTimeout(()=>this.$router.push('/documents'),[7000])
      
    }

  }
}
</script>
<style scoped>
@media(min-width: 573px){
  #webviewer {
    height: 80vh;
    overflow-y: scroll;
    position: relative;
  }
}
.controls-card{
  background-color: #ffffff;
  width: 250px;
  padding: 10px 15px;
  border-radius: 30px;
  border:1px solid #D0D5DD;
  bottom:3%;
  left: 40%;
}
@media (max-width:425px ) {
  .controls-card{
    background-color: #ffffff;
    width: 250px;
    padding: 10px 15px;
    border-radius: 30px;
    border:1px solid #D0D5DD;
    bottom:3%;
    left: 10%;
  }
}
.mx-8{
  margin-left: 8px;
  margin-right: 8px;
}
.px-8{
  padding-left: 8px;
  padding-right: 8px;
}
.disabled-btn{
  pointer-events: none;
  cursor:default;
  opacity: .5;
}
.vue-pdf-embed div {
  margin: 8px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
}
.pdf-viewer{
  width: 90%;
  height: 95%;
}
#webviewer::-webkit-scrollbar {
  width: 10px;
}

/* Track */
#webviewer::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px grey; 
  border-radius: 10px;
}
 
/* Handle */
#webviewer::-webkit-scrollbar-thumb {
  background: lightgray; 
  border-radius: 10px;
}

/* Handle on hover */
#webviewer::-webkit-scrollbar-thumb:hover {
  background: #004bff; 
}
.dragable-div-1{
  opacity: 0;
}
.dragable-div-2{
  opacity: 0;
}
.dragable-div-3{
  opacity: 0;
}
.dragable-div-4{
  opacity: 0;
}
.dragable-div-5{
  opacity: 0;
}
.dragable-div{
  position: absolute;
  /*width: 200px;
  height: 120px;*/
  border-radius: 6px;
  border:1px dashed #004bff;
  background: none;
  cursor: default;
}

.dragable-inner{
  border-top:1px solid #004bff;
  padding: 5px 10px;
  margin: 0px !important;
}
.email-avatar{
  text-align: center;
  vertical-align: middle;
  padding-top: 2px;
  height: 30px;
  width:30px;
  border-radius: 50%;
  margin-right: 10px;
  font-size: 18px;
  font-family: 400;
  color: #000000;
  background-color: #a1f3ff;
}
.upper-div{
  /*height: 75px;*/
  margin: 0px !important;
}

.reciever-sign{
  position: absolute;
  width: 200px;
  height: 120px;
  border-radius: 6px;
  border:1px dashed #004bff;
  background: none;
  cursor: default;
  opacity: 0;
}
@media(max-width:425px){
  .dragable-div{
    width: 100px;
    height: 70px;
    
  }
  .dragable-inner{
    border-top:1px solid #004bff;
    padding: 2px 5px;
    margin: 0px !important;
    font-size: 9px !important;
  }
  .email-avatar{
    text-align: center;
    vertical-align: middle;
    padding-top: 2px;
    height: 15px;
    width:15px;
    border-radius: 50%;
    margin-right: 5px;
    font-size: 10px;
    font-family: 400;
    color: #000000;
    background-color: #a1f3ff;
  }
  .upper-div{
    height: 40px;
    margin: 0px !important;
  }
  .reciever-sign{
    width: 100px;
    height: 60px;
    font-size:7px;
  }
}
.bg-div{
  background-color: #a1f3ff;
  width: 500px;
  height: 900px;
  position: absolute;
  top: 0px;
  left: 67px;
}
</style>