<template>
    <div class="dragable-div"> 
            <div class="upper-div"></div>
            <div class="dragable-inner d-flex align-items-center">
                <div class="email-avatar">D</div>
                Test Name
            </div>
 
    </div>
</template>
<script>
export default{
    props:{
        name:[String],
        email:[String],
    },
    data(){
        return{

        }
    }
}
</script>
<style scoped>
.dragable-div{
    position: absolute;
    width: 200px;
    height: 120px;
    border-radius: 6px;
    border:1px dashed blue;
    background: none;
    cursor: default;
}
.dragable-inner{
    border-top:1px solid blue;
    padding: 5px 10px;
}
.email-avatar{
    text-align: center;
    vertical-align: middle;
    padding-top: 2px;
    height: 30px;
    width:30px;
    border-radius: 50%;
    margin-right: 10px;
    font-size: 18px;
    font-family: 400;
    color: #000000;
    background-color: #a1f3ff;
}
.upper-div{
    height: 75px;
}
</style>