<template>
        <p class="label-style"><slot></slot></p>
</template>
<script>
export default{

}
</script>
<style scoped>
.label-style {
    color: #4B465C;
    font-size: 13px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 4px;
}
</style>